




import { Vue, Component } from 'vue-property-decorator'
import Default from '@/layouts/Default.vue'

@Component({
  components: {
    Default
  }
})
export default class App extends Vue {}

