import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const products = namespace('Products')

@Component
export default class ProductsMixin extends Vue {
  productNames: string[] = []

  @products.State
  public allProductNames!: string[]

  @products.Action
  public loadProducts!: () => Promise<void>
  
  async setProductNames() {
    await this.loadProducts()
    this.productNames = this.allProductNames.filter((product) => product )
  }
}