import { getEnvValue } from "./utils/env";
import {
  OptimalDesign,
  QscoreResponse,
  RotationPlan,
  InteractionResult,
} from "./response";
import { HeatMapDataType, InteractionDataType } from "./typedefs";

export function requestJson<Request, Reponse>(
  url: string,
  method: "GET" | "POST",
  body?: string
) {
  return new Promise((resolve: any, reject: any) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        try {
          const json = JSON.parse(this.responseText);
          resolve(json);
        } catch (err) {
          reject(new Error("Failed to parse json"));
        }
      } else {
        reject(new Error(this.statusText));
      }
    };

    xhr.onerror = function () {
      reject(new Error(this.statusText));
    };
    if (method === "POST") {
      xhr.setRequestHeader("Content-Type", "application/json");
    }
    if (body !== undefined) {
      xhr.send(body);
    } else {
      xhr.send();
    }
  });
}

function allCombinations(array: Array<any>) {
  return array.flatMap((v, i) => array.slice(i + 1).map((w) => [v, w]));
}

export async function getProductNames(): Promise<string[]> {
  const baseUrl = getEnvValue("VUE_APP_ZOOM_DB");
  const apiUrlProductNames = `${baseUrl}/allproductnames`;
  const response = await fetch(apiUrlProductNames);
  return response.json();
}

export async function getHostNames(): Promise<string[]> {
  const baseUrl = getEnvValue("VUE_APP_ZOOM_DB");
  const apiUrlHostNames = `${baseUrl}/allhostnames`;
  const response = await fetch(apiUrlHostNames);
  return response.json();
}

export async function getHostsByName(productName: string): Promise<string[]> {
  const baseUrl = getEnvValue("VUE_APP_ZOOM_DB");
  const apiUrlHosts = `${baseUrl}/get_finalchild_smaller_byname/${encodeURIComponent(
    productName
  )}`;
  const response = await fetch(apiUrlHosts);
  return response.json();
}

export async function getHostsByNames(
  productNames: string[]
): Promise<string[]> {
  let hosts: any[] = [];
  for (const product of productNames) {
    await getHostsByName(product)
      .then((response) => {
        hosts = [...new Set([...hosts, ...response])];
      })
      .catch(() => {
        console.warn("Product", product, "not found.");
      });
  }
  return hosts;
}

export async function getPhagesByName(hostName: string): Promise<string[]> {
  const baseUrl = getEnvValue("VUE_APP_ZOOM_DB");
  const apiUrlPhages = `${baseUrl}/get_phagelist_by_strain_name/${encodeURIComponent(
    hostName
  )}`;
  const response = await fetch(apiUrlPhages);
  return response.json();
}

export async function getPhagesByNames(hostNames: string[]): Promise<string[]> {
  let phages: any[] = [];
  for (const host of hostNames) {
    await getPhagesByName(host).then((response) => {
      phages = [...new Set([...phages, ...response])];
    });
  }
  return phages;
}

export async function getHostPhageInteraction(
  hostNames: string[]
): Promise<InteractionDataType[]> {
  const interactions: InteractionDataType[] = [];
  for (const host of hostNames) {
    const phages = await getPhagesByName(host);
    for (const phage of phages) {
      interactions.push({
        x: phage,
        y: host,
        risk: 100.0,
        union: NaN,
        intersection: NaN,
        overlap: NaN,
        similarity: NaN,
      });
    }
  }
  return interactions;
}

export function getHostHostRisk(
  host1: string,
  host2: string
): Promise<InteractionResult> {
  const baseUrl = getEnvValue("VUE_APP_ROBUROT");
  const apiUrlRisks = `${baseUrl}/risks/phage_risk?hosts=${encodeURIComponent(
    host1
  )}&hosts=${encodeURIComponent(host2)}`;
  const response = requestJson(
    apiUrlRisks,
    "GET"
  ) as Promise<InteractionResult>;
  return response;
}

export async function getHostHostRisks(
  hostNames: string[]
): Promise<InteractionDataType[]> {
  const risks: InteractionDataType[] = [];
  var allHostCombinations = allCombinations(hostNames);
  for (const host of hostNames) {
    allHostCombinations.push([host, host]);
  }

  for (const hosts of allHostCombinations) {
    getHostHostRisk(hosts[0], hosts[1]).then((response) => {
      if (response?.result !== undefined) {
        const result = response?.result;
        risks.push({ x: hosts[0], y: hosts[1], ...result });
        risks.push({ x: hosts[1], y: hosts[0], ...result });
      }
    });
  }
  return risks;
}

export function QScore(rotations: string[]): Promise<QscoreResponse> {
  const baseUrl = getEnvValue("VUE_APP_ROBUROT");
  const rotationPlan = rotations.join(";");
  const apiUrlRisks = `${baseUrl}/rotations/QScore?rotation_plan=${encodeURIComponent(
    rotationPlan
  )}`;
  const response = requestJson(apiUrlRisks, "GET") as Promise<QscoreResponse>;
  return response;
}

export async function getCustomCultureRisk(
  rotationHosts: Record<string, string[]>
): Promise<InteractionResult> {
  const baseUrl = getEnvValue("VUE_APP_ROBUROT");
  let apiUrlRisks = `${baseUrl}/risks/custom_culture_risk`;

  var customCultures: any[] = [];
  for (const rotation in rotationHosts) {
    customCultures.push({
      name: rotation,
      hosts: rotationHosts[rotation],
    });
  }
  const body = JSON.stringify(customCultures);
  const response = requestJson(
    apiUrlRisks,
    "POST",
    body
  ) as Promise<InteractionResult>;
  return response;
}

export async function getCultureCultureInteraction(
  rotationHosts: Record<string, string[]>
): Promise<InteractionDataType[]> {
  const interactions: any[] = [];

  var rotations: string[] = [];
  for (const rotation in rotationHosts) {
    rotations.push(rotation);
  }

  const allRotationCombinations = allCombinations(rotations);

  for (const culturePair of allRotationCombinations) {
    var customRotations: Record<string, string[]> = {};
    customRotations[culturePair[0]] = rotationHosts[culturePair[0]];
    customRotations[culturePair[1]] = rotationHosts[culturePair[1]];
    const response = await getCustomCultureRisk(customRotations);
    interactions.push({
      x: culturePair[0],
      y: culturePair[1],
      ...response.result,
    });
    interactions.push({
      x: culturePair[1],
      y: culturePair[0],
      ...response.result,
    });
  }

  for (const rotation in rotationHosts) {
    var customRotations: Record<string, string[]> = {};
    customRotations[rotation] = rotationHosts[rotation];
    const intraCultureResponse = await getCustomCultureRisk(customRotations);
    interactions.push({
      x: rotation,
      y: rotation,
      ...intraCultureResponse.result,
    });
  }
  return interactions;
}

export function optimalRotationPlan(
  rotationHosts: Record<string, string[]>,
  amount: number = 20,
  threshold: number = 0.5
): Promise<RotationPlan> {
  const baseUrl = getEnvValue("VUE_APP_ROBUROT");
  let apiUrlRotations = `${baseUrl}/rotations/optimal_rotation_plan?`;
  apiUrlRotations = `${apiUrlRotations}amount_plans=${encodeURIComponent(
    amount
  )}&q_score_threshold=${encodeURIComponent(threshold)}`;

  var customCultures: any[] = [];
  for (const rotation in rotationHosts) {
    customCultures.push({
      name: rotation,
      hosts: rotationHosts[rotation],
    });
  }
  const body = JSON.stringify(customCultures);

  const response = requestJson(
    apiUrlRotations,
    "POST",
    body
  ) as Promise<RotationPlan>;

  return response;
}

export function optimalDesign(
  bins: string[][],
  amountRotations: number
): Promise<OptimalDesign> {
  const baseUrl = getEnvValue("VUE_APP_ROBUROT");
  const apiUrlDesign = `${baseUrl}/blend_design/optimize`;
  const body = JSON.stringify({
    functionality_bins: bins,
    num_cultures: amountRotations,
  });
  const response = requestJson(
    apiUrlDesign,
    "POST",
    body
  ) as Promise<OptimalDesign>;
  return response;
}
