import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { getProductNames, getHostsByName } from "@/lib/requests";
import Vue from "vue";

@Module({ namespaced: true })
class Products extends VuexModule {
  public allProductNames: string[] = [];
  private productHosts: Record<string, string[]> = {};
  private lastFetch: number = 0;

  private get shouldUpdate() {
    // Only update every hour (data won't change that often)
    // To override (e.g. changing backend) set lastFetch to 0 or null
    const lastFetch = this.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 3600;
  }

  @Mutation
  public setProducts(productNames: string[]): void {
    this.allProductNames = productNames;
  }

  @Mutation
  public addProductHosts(payload: any): void {
    Vue.set(this.productHosts, payload.productName, payload.hosts);
  }

  @Mutation
  public setFetchTimestamp() {
    this.lastFetch = new Date().getTime();
  }

  @Action
  public async loadProducts(): Promise<void> {
    if (!this.shouldUpdate) {
      return;
    }
    const allProductNames = await getProductNames();
    this.context.commit("setProducts", allProductNames);
    this.context.commit("setFetchTimestamp");
  }

  @Action
  public async loadProductHosts(productName: string): Promise<void> {
    if (this.productHosts.hasOwnProperty(productName)) {
      return;
    }
    const hosts = await getHostsByName(productName);
    this.context.commit("addProductHosts", {
      productName: productName,
      hosts: hosts,
    });
  }
}
export default Products;
