










































































































import { Component, Mixins } from "vue-property-decorator";
import { getHostNames, optimalDesign } from "@/lib/requests";
import { OptimalDesign } from "@/lib/response";
import InputColumn from "@/components/InputColumn.vue";
import DataColumn from "@/components/DataColumn.vue";
import DataSelect from "@/components/DataSelect.vue";
import ProductsMixin from "@/mixins/ProductsMixin";
import RotationsTable from "@/components/RotationsTable.vue";

@Component({
  components: {
    InputColumn,
    DataColumn,
    DataSelect,
    RotationsTable,
  },
})
export default class StrainBlender extends Mixins(ProductsMixin) {
  bins: string[][] = [[]];
  hosts: string[] = [];
  numberRotations: number = 3;
  minRotations: number = 1;
  maxRotations: number = 15;
  gettingDesign: boolean = false;

  rotationOrders: {
    rotation: string[];
    qscore: number;
  }[] = [];

  async created() {
    await this.setHostNames();
  }

  // mounted() {
  //   this.onHostValueChange(["M124","2186","478","200-M","LL-710","584","4108"],0)
  //   this.onHostValueChange(["LL-068","LL-481","LL-710","LL-064","LL-451","4175","M249"],1)
  //   this.onHostValueChange(["3604","M154","LL-480","LL-745","M276","LL-715","4108"],2)
  // }

  async setHostNames() {
    this.hosts = await getHostNames();
  }

  addBin() {
    this.bins.push([]);
  }

  removeBin(index: number) {
    if (index > -1) {
      this.bins.splice(index, 1);
    }
  }

  onHostValueChange(value: any, index: number) {
    this.bins[index] = value;
  }

  async getOptimalDesign() {
    this.gettingDesign = true;
    this.rotationOrders = [];
    await optimalDesign(this.bins, this.numberRotations).then(
      (response: OptimalDesign) => {
        for (const design of response.result) {
          let rotation: string[] = [];
          for (const culture of design.Rotations) {
            rotation.push(culture.toString());
          }
          const qScore = design["Q Score"];
          this.rotationOrders.push({ rotation: rotation, qscore: qScore });
        }
      }
    );
    this.gettingDesign = false;
  }
}
