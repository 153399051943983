import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { getHostNames } from "@/lib/requests";

@Module({ namespaced: true })
class Hosts extends VuexModule {
  public allHostNames: string[] = [];
  private lastFetch: number = 0;

  private get shouldUpdate() {
    // Only update every hour (data won't change that often)
    // To override (e.g. changing backend) set lastFetch to 0 or null
    const lastFetch = this.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 3600;
  }

  @Mutation
  public setHosts(hostNames: string[]): void {
    this.allHostNames = hostNames;
  }

  @Mutation
  public setFetchTimestamp() {
    this.lastFetch = new Date().getTime();
  }

  @Action
  public async loadHosts(): Promise<void> {
    if (!this.shouldUpdate) {
      return;
    }
    const allHostNames = await getHostNames();
    this.context.commit("setHosts", allHostNames);
    this.context.commit("setFetchTimestamp");
  }
}
export default Hosts;
