import Vue from "vue";
import Vuex from "vuex";
import Products from "@/store/modules/products";
import Hosts from "@/store/modules/hosts";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Products,
    Hosts,
  },
});
export default store;
