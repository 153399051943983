




















































































































import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  getHostNames,
  getPhagesByNames,
  getHostPhageInteraction,
  getHostHostRisks,
  getCultureCultureInteraction,
  optimalRotationPlan,
} from "@/lib/requests";
import {
  HeatMapDataType,
  emptyHeatMapData,
  InteractionDataType,
} from "@/lib/typedefs";
import { RotationPlan } from "@/lib/response";
import ProductsMixin from "@/mixins/ProductsMixin";
import InputColumn from "@/components/InputColumn.vue";
import DataColumn from "@/components/DataColumn.vue";
import DataSelect from "@/components/DataSelect.vue";
import HeatmapEcharts from "@/components/Heatmap.vue";
import RotationsTable from "@/components/RotationsTable.vue";
import RotationsDonut from "@/components/RotationsDonut.vue";

const products = namespace("Products");
const hosts = namespace("Hosts");

@Component({
  components: {
    InputColumn,
    DataColumn,
    DataSelect,
    HeatmapEcharts,
    RotationsTable,
    RotationsDonut,
  },
})
export default class RobuRot extends Mixins(ProductsMixin) {
  selectedRotations: string[] = [];
  phages!: string[];
  selectedHosts: Record<string, string[]> = {};
  usedHosts: string[] = [];

  interactionDataHostPhage: HeatMapDataType = emptyHeatMapData;
  riskDataHostHost: HeatMapDataType = emptyHeatMapData;
  interactionDataCultureCulture: HeatMapDataType = emptyHeatMapData;

  rotationOrders: {
    rotation: string[];
    qscore: number;
  }[] = [];

  selectedOrder: {
    rotation: string[];
    qscore: number;
  } = { rotation: [], qscore: 1 };

  rotationPlanHosts: Record<string, string[]> = {};

  gettingRotationPlan = false;

  @products.Action
  public loadProductHosts!: (productName: string) => Promise<void>;

  @products.State("productHosts")
  productHosts!: Record<string, string[]>;

  @hosts.State("allHostNames")
  allHostNames!: string[];

  @hosts.Action
  public loadHosts!: () => Promise<void>;

  async created() {
    this.setProductNames();
    this.loadHosts();
  }

  async setPhageNames() {
    this.phages = await getPhagesByNames(this.usedHosts);
  }

  async setHeatmapData() {
    let interactions: InteractionDataType[] = [];
    let risks: InteractionDataType[] = [];
    let interactionsCulture: InteractionDataType[] = [];

    if (this.usedHosts.length > 0) {
      await this.setPhageNames();
      interactions = await getHostPhageInteraction(this.usedHosts);
      risks = await getHostHostRisks(this.usedHosts);
      interactionsCulture = await getCultureCultureInteraction(
        this.selectedHosts
      );
    }

    this.interactionDataHostPhage = {
      xdata: this.phages,
      ydata: this.usedHosts,
      data: interactions,
      xlabel: "PHAGES",
      ylabel: "HOSTS",
      showLegend: false,
    };
    this.riskDataHostHost = {
      xdata: this.usedHosts,
      ydata: this.usedHosts,
      data: risks,
      xlabel: "HOSTS",
      ylabel: "HOSTS",
      showLegend: true,
    };
    this.interactionDataCultureCulture = {
      xdata: this.selectedRotations,
      ydata: this.selectedRotations,
      data: interactionsCulture,
      xlabel: "ROTATIONS",
      ylabel: "ROTATIONS",
      showLegend: true,
    };
  }

  async setRotationQScores() {
    this.gettingRotationPlan = true;
    this.selectedOrder = { rotation: [], qscore: 1 };
    if (this.selectedRotations.length > 1) {
      this.rotationOrders = [];
      await optimalRotationPlan(this.selectedHosts).then(
        (response: RotationPlan) => {
          for (const rotationScore of response.result) {
            const rotationOrder = Object.keys(rotationScore)[0];
            const qScore = rotationScore[rotationOrder];
            const rotation = rotationOrder.split(",");
            this.rotationOrders.push({ rotation: rotation, qscore: qScore });
          }
          this.rotationPlanHosts = this.selectedHosts;
          this.selectedOrder = this.rotationOrders[0];
        }
      );
    }
    this.gettingRotationPlan = false;
  }

  setUsedHosts() {
    var hosts: string[] = [];
    for (const host in this.selectedHosts) {
      hosts = [...new Set([...hosts, ...this.selectedHosts[host]])];
    }
    this.usedHosts = hosts;
  }

  get rotationPlanButton() {
    return this.gettingRotationPlan || this.selectedRotations.length < 2;
  }

  onBlur() {
    this.setHeatmapData();
  }

  async onRotationValueChange(
    newSelection: string[],
    addedItems: string[],
    removedItems: string[]
  ) {
    this.selectedRotations = newSelection;
    for (const item of addedItems) {
      await this.loadProductHosts(item);
      this.selectedHosts[item] = this.productHosts[item];
    }
    for (const item of removedItems) {
      delete this.selectedHosts[item];
    }
    this.setUsedHosts();
  }

  onHostValueChange(value: any, product: string) {
    this.selectedHosts[product] = value;
    this.setUsedHosts();
  }

  onSelectedOrder(selectedPlan: any) {
    this.selectedOrder = selectedPlan;
  }

  get shownProducts() {
    return this.selectedRotations;
  }

  get shownHosts() {
    return this.selectedHosts;
  }
}
