import Vue from "vue";
import vuetify from "@/plugins/vuetify"
import "vuetify/dist/vuetify.min.css" // Ensure you are using css-loader
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import Default from "@/layouts/Default.vue"
Vue.component("default-layout", Default)

import ECharts from "vue-echarts";
import { use } from 'echarts/core'
// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  HeatmapChart,
  PieChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
  TitleComponent
} from 'echarts/components'

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

use([
  CanvasRenderer,
  HeatmapChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  VisualMapComponent,
  TitleComponent
]);
Vue.component("v-chart", ECharts)

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
