






import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import ECharts from "vue-echarts";
import { getCustomCultureRisk } from "@/lib/requests";

@Component({
  components: {
    "v-chart": ECharts,
  },
})
export default class RotationsDonut extends Vue {
  @Prop() rotationPlan!: { rotation: string[]; qscore: number };
  @Prop() rotationHosts!: Record<string, string[]>;

  data: any[] = [];
  donutOptions = {
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        return `${params.data.hoverLabel}`;
      },
    },
    title: {
      text: "Q-score:\n %",
      left: "center",
      top: "center",
    },
    series: [
      {
        type: "pie",
        name: "Rotation ID",
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        data: this.data,
        radius: ["40%", "70%"],
      },
    ],
  };

  @Watch("rotationPlan", { deep: true })
  updateChart() {
    this.setData();
    this.setLabel();
  }

  getNeighbors(rotation: string) {
    var neighbours: string[] = [];
    var idx = this.rotationPlan.rotation.indexOf(rotation);
    var lastIndex = this.rotationPlan.rotation.length - 1;
    if (idx == 0) {
      neighbours.push(this.rotationPlan.rotation[lastIndex]);
      neighbours.push(this.rotationPlan.rotation[1]);
    } else if (idx == lastIndex) {
      neighbours.push(this.rotationPlan.rotation[lastIndex - 1]);
      neighbours.push(this.rotationPlan.rotation[0]);
    } else {
      neighbours.push(this.rotationPlan.rotation[idx - 1]);
      neighbours.push(this.rotationPlan.rotation[idx + 1]);
    }
    return neighbours;
  }

  setLabel() {
    this.donutOptions.title.text = `Q-score:\n ${(
      this.rotationPlan.qscore * 100
    ).toFixed(1)}%`;
  }

  async setData() {
    var pieces: any = [];
    if (this.rotationPlan && this.rotationPlan.rotation.length > 0) {
      for (const rotation of this.rotationPlan.rotation) {
        var neighbours = this.getNeighbors(rotation);
        var customRotations: Record<string, string[]> = {};
        var customRotations2: Record<string, string[]> = {};
        customRotations[rotation] = this.rotationHosts[rotation];
        customRotations[neighbours[0]] = this.rotationHosts[neighbours[0]];
        var interactionsCulture1 = await getCustomCultureRisk(customRotations);

        customRotations2[rotation] = this.rotationHosts[rotation];
        customRotations2[neighbours[1]] = this.rotationHosts[neighbours[1]];
        var interactionsCulture2 = await getCustomCultureRisk(customRotations2);

        var hoverLabel = `<strong>Neighbor interaction </strong><br />
              <table>
              <tr><td align='right'>${rotation} - ${
          neighbours[0]
        }: </td><td>${interactionsCulture1.result.risk.toFixed(1)}%</td></tr>
              <tr><td align='right'>${rotation} - ${
          neighbours[1]
        }: </td><td>${interactionsCulture2.result.risk.toFixed(1)}%</td></tr>
              </table>`;

        pieces.push({
          value: 1,
          name: rotation,
          hoverLabel: hoverLabel,
        });
      }
    }
    this.donutOptions.series[0].data = pieces;
  }
}
