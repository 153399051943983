


































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RotationsTable extends Vue {
  emits: ["select"] | undefined;

  @Prop() rotationOrders!: { rotation: string[]; qscore: number }[];
  maxRotations: number = 0;

  get rotationLength() {
    if (this.rotationOrders.length > 0) {
      return this.rotationOrders[0].rotation.length;
    }
    return 1;
  }

  formattedQScore(qscore: number) {
    return (qscore * 100).toFixed(1);
  }

  onClick(rotation: string[], qscore: number) {
    this.$emit("select", { rotation: rotation, qscore: qscore });
  }
}
