




























import { Vue, Component } from "vue-property-decorator";

@Component
export default class Default extends Vue {
  get routes() {
    if (this.$router.options.routes !== undefined) {
      const routes = this.$router.options.routes.filter(
        (route) => route.meta !== undefined && route.meta.tab
      );
      return routes;
    }
    return [];
  }
}
