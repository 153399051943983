






import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HeatMapDataType, emptyHeatMapData } from "@/lib/typedefs";
import ECharts from "vue-echarts";

@Component({
  components: {
    "v-chart": ECharts,
  },
})
export default class HeatmapEcharts extends Vue {
  @Prop({
    default: () => {
      return emptyHeatMapData;
    },
  })
  heatmapData!: HeatMapDataType;

  heatmapOptions: any = {};
  seriesOptions: any = {
    type: "heatmap",
    data: [],
    label: {
      show: false,
    },
    seriesIndex: 2,
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: "rgba(0, 0, 0, 0.5)",
      },
    },
    tooltip: {
      show: true,
      formatter: (params: any) => {
        if (isNaN(params.value[3])) {
          return `${params.value[0]} - ${params.value[1]}`;
        }
        if (params.value[0] == params.value[1]) {
          return `<strong>${params.value[0]} </strong><br />
              <table>
              <tr><td align='right'>phage count: </td><td>${params.value[3]}  </td></tr>
              <tr><td align='right'>overlap count: </td><td>${params.value[4]}  </td></tr>
              <tr><td align='right'>similarity: </td><td>${params.value[5]}% </td></tr>
              <tr><td align='right'>overlap: </td><td>${params.value[6]}% </td></tr>
              <tr><td align='right'>intraculture risk: </td><td>${params.value[2]}% </td></tr>
              </table>`;
        }
        return `<strong>${params.value[0]} - ${params.value[1]} </strong><br />
              <table>
              <tr><td align='right'>union: </td><td>${params.value[3]}  </td></tr>
              <tr><td align='right'>intersection: </td><td>${params.value[4]}  </td></tr>
              <tr><td align='right'>similarity: </td><td>${params.value[5]}% </td></tr>
              <tr><td align='right'>overlap: </td><td>${params.value[6]}% </td></tr>
              <tr><td align='right'>risk: </td><td>${params.value[2]}% </td></tr>
              </table>`;
      },
    },
  };
  fixedOptions: any = {
    tooltip: {
      position: "top",
    },
    animation: false,
    lazyUpdate: true,
    notMerge: true,
    grid: {
      height: "50%",
      top: "10%",
      // containLabel: true,
    },
    xAxis: {
      type: "category",
      splitArea: {
        show: true,
      },
      axisLabel: {
        interval: 0,
        rotate: 60,
      },
      name: "",
      nameLocation: "middle",
      nameGap: 120,
      nameTextStyle: {
        fontWeight: "bold",
      },
    },
    yAxis: {
      type: "category",
      axisLabel: {
        interval: 0,
      },
      splitArea: {
        show: true,
      },
      name: "",
      nameLocation: "middle",
      nameGap: 50,
      nameTextStyle: {
        fontWeight: "bold",
      },
    },
    visualMap: {
      type: "piecewise",
      pieces: [
        { min: 0, max: 1 },
        { min: 1, max: 20 },
        { min: 20, max: 40 },
        { min: 40, max: 100 },
      ],
      dimension: 2,
      inverse: true,
      min: 0,
      max: 100,
      calculable: true,
      inRange: {
        color: ["#ffffff", "#000000"],
      },
      bottom: "60%",
      left: "90%",
      show: true,
      formatter: "{value} - {value2} %",
    },
    series: [this.seriesOptions],
  };

  mounted() {
    this.setData();
  }

  setSeriesData() {
    if (this.heatmapData !== undefined) {
      const data: any[] = [];

      for (const dataPoint of this.heatmapData.data) {
        data.push([
          dataPoint.x,
          dataPoint.y,
          dataPoint.risk,
          dataPoint.union,
          dataPoint.intersection,
          dataPoint.overlap,
          dataPoint.similarity,
        ]);
      }
      this.seriesOptions.data = data;
    }
  }

  setData() {
    this.heatmapOptions = this.fixedOptions;

    if (this.heatmapData !== undefined) {
      this.heatmapOptions.xAxis.data = this.heatmapData.xdata;
      this.heatmapOptions.yAxis.data = this.heatmapData.ydata;
      this.heatmapOptions.xAxis.name = this.heatmapData.xlabel;
      this.heatmapOptions.yAxis.name = this.heatmapData.ylabel;
      this.heatmapOptions.visualMap.show = this.heatmapData.showLegend;
      this.setSeriesData();
      this.heatmapOptions.series[0] = this.seriesOptions;
    }
  }

  @Watch("heatmapData", { deep: true })
  updateChart() {
    this.setData();
  }
}
