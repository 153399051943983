
















































import { Component, Mixins } from "vue-property-decorator";
import {
  getHostsByNames,
  getPhagesByNames,
  getHostPhageInteraction,
} from "@/lib/requests";
import { HeatMapDataType, emptyHeatMapData } from "@/lib/typedefs";
import ProductsMixin from "@/mixins/ProductsMixin";
import InputColumn from "@/components/InputColumn.vue";
import DataColumn from "@/components/DataColumn.vue";
import DataSelect from "@/components/DataSelect.vue";
import HeatmapEcharts from "@/components/Heatmap.vue";

@Component({
  components: {
    InputColumn,
    DataColumn,
    DataSelect,
    HeatmapEcharts,
  },
})
export default class HostPhage extends Mixins(ProductsMixin) {
  productNames: string[] = [];
  selectedProducts: string[] = [""];
  hosts!: string[];
  phages!: string[];
  heatmapData: HeatMapDataType = emptyHeatMapData;

  async created() {
    this.setProductNames();
    this.selectedProducts = [this.productNames[0]];
    await this.setHeatmapData();
  }

  async setHostNames() {
    this.hosts = await getHostsByNames(this.selectedProducts);
  }

  async setPhageNames() {
    this.phages = await getPhagesByNames(this.hosts);
  }

  async setHeatmapData() {
    await this.setHostNames();
    await this.setPhageNames();
    const interactions = await getHostPhageInteraction(this.hosts);
    this.heatmapData = {
      xdata: this.phages,
      ydata: this.hosts,
      data: interactions,
      xlabel: "PHAGES",
      ylabel: "HOSTS",
      showLegend: false,
    };
  }

  async onValueChange(value: any) {
    this.selectedProducts = value;
    await this.setHeatmapData();
  }

  get shownProducts() {
    return this.selectedProducts;
  }
}
