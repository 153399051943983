export interface InteractionDataType {
  x: string;
  y: string;
  risk: number;
  union: number;
  intersection: number;
  similarity: number;
  overlap: number;
}

export interface HeatMapDataType {
  xdata: string[];
  ydata: string[];
  data: InteractionDataType[];
  xlabel: string;
  ylabel: string;
  showLegend: boolean;
}

export const emptyHeatMapData = {
  xdata: [""],
  ydata: [""],
  data: [
    {
      x: "",
      y: "",
      risk: NaN,
      union: NaN,
      intersection: NaN,
      similarity: NaN,
      overlap: NaN,
    },
  ],
  xlabel: "",
  ylabel: "",
  showLegend: false,
};
