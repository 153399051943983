



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DataSelect extends Vue {
  @Prop() allItems!: any[];
  @Prop() label!: string;
  @Prop() shownItems!: any;
  loading: string | boolean = "primary";
  selectedItems: any = "";
  items: any[] = [];

  created() {
    this.items = this.allItems;
    this.toggleLoading();
    this.selectedItems = this.shownItems;
  }

  onBlur() {
    this.$emit("blur");
  }

  searchItem(val: string) {
    if (val) {
      this.items = this.allItems.filter(
        (item) => item.toLowerCase().indexOf(val) !== -1
      );
      if (this.items.length == 0) {
        this.items = this.allItems;
      }
    } else {
      this.items = this.allItems;
    }
  }

  onValueChange(oldItems: string[], newItems: string[]) {
    var addedItems: string[] = [];
    var removedItems: string[] = [];
    this.selectedItems = newItems;

    if (!oldItems || oldItems.length < 1) {
      addedItems = this.selectedItems;
    } else if (!this.selectedItems || this.selectedItems.length < 1) {
      removedItems = oldItems;
    } else {
      addedItems = this.selectedItems.filter(
        (v: string) => !oldItems.includes(v)
      );
      removedItems = oldItems.filter((v: any) => !newItems.includes(v));
    }

    this.$emit("change", this.selectedItems, addedItems, removedItems);
  }

  @Watch("shownItems")
  setValue() {
    this.selectedItems = this.shownItems;
  }

  @Watch("allItems")
  setAllItems() {
    this.items = this.allItems;
  }

  @Watch("items")
  toggleLoading() {
    if (this.items && this.items.length > 0) {
      this.loading = false;
    } else {
      this.loading = "primary";
    }
  }
}
