






























import { Vue, Component } from "vue-property-decorator";
import InputColumn from "@/components/InputColumn.vue"
import DataColumn from "@/components/DataColumn.vue"

@Component({
  components: {
    InputColumn,
    DataColumn
  }
})
export default class Home extends Vue {}
