import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import HostPhage from "../views/HostPhage.vue";
import RobuRot from "../views/RobuRot.vue";
import StrainBlender from "../views/StrainBlender.vue"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {tab :true},
  },
  {
    path: "/host-phage",
    name: "Host-Phage",
    component: HostPhage,
    meta: {tab :true},
  },
  {
    path: "/roburot",
    name: "RobuRot",
    component: RobuRot,
    meta: {tab :true},
  },
  {
    path: "/strain-blender",
    name: "Strain-blender",
    component: StrainBlender,
    meta: {tab :true},
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
